import './showdetails.css'
function Details() {
    return (
        <div align="center" className="details-div">
            <p><a href="mailto:naveen@syrusdark.website" className="no-link">Naveen@syrusdark</a></p>
            <span class="subtitle">High Schooler</span>
            <p>
                Hey, I’m Naveen. I’m a student living in India.
            </p>
            <p>
                I am a fan of many things and I ♥️ Open Source.
            </p>
            <p>
                I love using Windows 😉
            </p>
            <p>Also, I love listening to Music.</p>
            <p>I write a blog in my free time.</p>
            <p>which usually seems to empty because I have so less free time ;)</p>
            <p>On discord, I'm syrus_dark#0746.</p>
            <p>Get in touch: <a href="mailto:naveen@syrusdark.website" className="no-link">naveen@syrusdark.website</a></p>
        </div>
    );
}

export default Details;
