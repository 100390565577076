import './App.css';
import Name from './showname';
import Image from './showprofphoto';
import Details from './showdetails'
function App() {
  return (
    <div class="contents">
      <Name />
      <Image />
      <Details />
    </div>
  );
}

export default App;
