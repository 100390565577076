import './name.css';
import Bounce from 'react-reveal/Bounce';

function Name() {
    return (
      <Bounce top opposite cascade collapse>
        <div className="centered">
            <span class="name">Naveen M K</span>
        </div>
      </Bounce>
    );
  }

export default Name;