/*
Show the image from my Github account. I don't think I should
add the image here so that I don't need to update here when
I want to change :)
I am lazy...
*/
import './profphoto.css';
function Image() {
    return (
        <div className="centered">
            <img className="profile-image" alt="Naveen M K" src="https://avatars1.githubusercontent.com/u/49693820?v=4" />
        </div>
    );
}

export default Image;